import React, { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { useSession } from 'next-auth/client';
import { get } from 'lodash';

const Autocomplete = ({
  searchFor,
  name,
  placeholder,
  setFieldValue,
  value = '',
  region = '',
  constituency = '',
  district = '',
}) => {
  const [activeSuggestion, setActiveSuggestion] = useState(value);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState(value);
  const [session] = useSession();
  const [loading, setLoading] = useState(false);

  const [debouncedSearch] = useDebounce(userInput, 700);

  const onChange = e => {
    setUserInput(e.currentTarget.value);
    setActiveSuggestion(0);
    setFieldValue(name, '');
  };

  const resetState = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 500);
  };

  const fetchSuggestions = async () => {
    if (!debouncedSearch) {
      setActiveSuggestion(0);
      setFilteredSuggestions([]);
      setShowSuggestions(false);
      setUserInput('');
      return;
    }
    if (activeSuggestion) {
      return;
    }
    setLoading(true);
    try {
      let url = '';
      if (searchFor === 'groups') {
        url = `https://nec.dmb2024.com/Groups?Name=${debouncedSearch}`;
      } else if (searchFor === 'contacts') {
        url = `https://nec.dmb2024.com/Contacts/search?Term=${debouncedSearch}`;
      } else {
        url = `https://nec.dmb2024.com/Contacts/search-fields?Field=${searchFor}&Term=${debouncedSearch}`;
      }
      const response = await fetch(
        `${url}&PageSize=5${constituency && '&Constituency=' + constituency}${
          region && '&Region=' + region
        }
        ${district && '&District=' + district}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${session.token}`,
          },
        }
      );
      const status = response.status;
      switch (status) {
        case 200: {
          const results = await response.json();
          setFilteredSuggestions(get(results, 'data.results'));
          setShowSuggestions(true);
          return;
        }
        case 401:
          //return unauthorized
          break;
        case 500:
          // internal server error
          break;
        default:
        //notify user of error that occurred
      }
    } catch (err) {
      //todo notify user of error
    } finally {
      setLoading(false);
    }
  };

  const handleClick = item => {
    setActiveSuggestion(item);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(searchFor === 'groups' ? item.name : item);
    setFieldValue(name, searchFor === 'groups' ? item.id : item);
  };

  useEffect(() => {
    fetchSuggestions();
  }, [debouncedSearch]);

  const suggestions = () => {
    if (loading) {
      return (
        <div id='myInputautocomplete-list' className='autocomplete-items'>
          <div>
            <em>Loading ...</em>
          </div>
        </div>
      );
    }

    if (showSuggestions && userInput) {
      return (
        <div id='myInputautocomplete-list' className='autocomplete-items'>
          {filteredSuggestions.length > 0 ? (
            filteredSuggestions.map((item, index) => (
              <div key={index} onClick={() => handleClick(item)}>
                {get(item, 'name') || get(item, 'fullname') || item}
                <input
                  type='hidden'
                  value={searchFor === 'groups' ? item.id : item}
                />
              </div>
            ))
          ) : (
            <div>
              <em>No suggestions available.</em>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <input
        type='text'
        className='form-control border-left-0 search-input'
        placeholder={placeholder}
        value={userInput}
        onChange={onChange}
        name={name}
        onBlur={resetState}
      />
      {suggestions()}
    </>
  );
};

export default Autocomplete;
