import HeadComponent from "../components/head";
import { signIn } from "next-auth/client";

export default function SignIn() {
  return (
    <>
      <HeadComponent />

      <div className="container">
        <div className="row align-items-center mymain mycontainer">
          <div className="col-12 mx-auto">
            <div className="card col-12 col-sm-10 col-md-6 col-lg-5 m-auto">
              <div className="card-body m-auto text-center pb-5">
                <h6 className="my-4 font-weight-bold">
                  Login is restricted to authorised persons only
                </h6>
                <div align="center" className="mt-4 py-2">
                  <a
                    onClick={() => signIn("google")}
                    className="btn btn-primary d-block py-3 mb-2"
                  >
                    <img
                      src="https://designs.dmb2024.com/assets/images/logos/google-logo.svg"
                      className="mr-2 ml-n1"
                    />
                    Sign in with Google
                  </a>

                  <a
                    onClick={() => signIn("azure-ad-b2c")}
                    className="btn btn-dark d-block py-3"
                  >
                    <img
                      src="https://designs.dmb2024.com/assets/images/logos/microsoft-logo.svg"
                      className="mb-1 mr-1 ml-0"
                    />
                    Sign in with Microsoft
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
