const EmptyContent = () => (
  <div className="row">
    <div className="col-12 col-md-12 mb-4">
      <div className="card custom-card">
        <div className="card-body text-center my-5 pb-1">
          <div>
            <img
              src="https://designs.dmb2024.com/assets/images/icons/empty-folder.svg"
              width="40"
              className="img-fluid"
              alt="empty-state"
            />
          </div>
          <p className="small mt-3 font-weight-bold">
            This section is currently empty
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default EmptyContent;
