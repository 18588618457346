import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import Autocomplete from '../ui/Autocomplete';
import { contactFieldSearch } from '../../utils';
import { useSession } from 'next-auth/client';

const ContactSearchFilterForm = ({
	isSubmitting,
	handleChange,
	handleSubmit,
	handleReset,
	errors,
	setFieldValue,
	touched,
	values: { constituency, region, pollingStation, group },
}) => {
	const [session] = useSession();
	const [pollingStations, setPollingStations] = useState([]);
	const [constituencies, setConstituencies] = useState([]);
	const [loadingConstituency, setLoadingConstituency] =
		useState('Select a region');
	const [loadingPolling, setLoadingPolling] = useState('Select a constituency');

	const fetchConstituencies = async () => {
		try {
			const { status, results } = await contactFieldSearch({
				region,
				field: 'constituency',
				term: '*',
				token: session.token,
			});
			if (status !== 200) {
				return;
			}
			setConstituencies(results.data.results);
		} catch (err) {}
	};

	const fetchPollingStation = async () => {
		try {
			const { status, results } = await contactFieldSearch({
				region,
				constituency,
				field: 'pollingStation',
				term: '*',
				token: session.token,
			});
			if (status !== 200) {
				return;
			}
			setPollingStations(results.data.results);
		} catch (err) {}
	};

	const handleRegionChange = (value) => {
		setLoadingConstituency('Loading...');
		setFieldValue('region', value);
		setFieldValue('constituency', '');
		setFieldValue('pollingStation', '');
	};

	const handleConstituencyChange = (value) => {
		setLoadingPolling('Loading...');
		setFieldValue('constituency', value);
		setFieldValue('pollingStation', '');
	};

	useEffect(() => {
		region && fetchConstituencies();
	}, [region]);

	useEffect(() => {
		region && constituency && fetchPollingStation();
	}, [region, constituency]);

	return (
		<form action="" onSubmit={handleSubmit} autoComplete="off">
			<div className="mb-3">
				<label htmlFor="region">Region</label>
				<div className="input-group">
					<div className="input-group-prepend">
						<img
							src="https://designs.dmb2024.com/assets/images/icons/map.svg"
							width="40"
							className="input-group-text bg-white img-fluid text-primary"
							alt="map"
						/>
					</div>
					<select
						className="form-control border-left-0"
						name="region"
						id="region"
						value={region}
						onChange={(e) => handleRegionChange(e.target.value)}
					>
						<option value="" disabled>
							-- Select One --
						</option>
						<option value="AHAFO">AHAFO</option>
						<option value="ASHANTI">ASHANTI</option>
						<option value="BONO">BONO</option>
						<option value="BONO EAST">BONO EAST</option>
						<option value="CENTRAL">CENTRAL</option>
						<option value="EASTERN">EASTERN</option>
						<option value="GREATER ACCRA">GREATER ACCRA</option>
						<option value="NORTHERN">NORTHERN</option>
						<option value="NORTH EAST">NORTH EAST</option>
						<option value="VOLTA">VOLTA</option>
						<option value="OTI">OTI</option>
						<option value="SAVANNAH">SAVANNAH</option>
						<option value="UPPER EAST">UPPER EAST</option>
						<option value="UPPER WEST">UPPER WEST</option>
						<option value="WESTERN">WESTERN</option>
						<option value="WESTERN NORTH">WESTERN NORTH</option>
					</select>
				</div>
			</div>

			<label htmlFor="constituency">Constituency</label>
			<div className="input-group mb-3">
				<div className="input-group-prepend">
					<img
						src="https://designs.dmb2024.com/assets/images/icons/map-pin.svg"
						width="40"
						className="input-group-text bg-white img-fluid"
						alt=""
					/>
				</div>
				{constituencies.length < 1 ? (
					<select className="custom-select" disabled>
						<option value="">{loadingConstituency}</option>
					</select>
				) : (
					<select
						className="custom-select"
						name="constituency"
						id="constituency"
						value={constituency}
						onChange={(e) => handleConstituencyChange(e.target.value)}
					>
						<option value="">Select a Constituency</option>
						{constituencies
							.sort((a, b) => a.localeCompare(b))
							.map((item, index) => (
								<option value={item} key={index}>
									{item}
								</option>
							))}
					</select>
				)}
			</div>
			<div>
				<label htmlFor="pollingStation">Polling station</label>
				<div className="input-group mb-3">
					<div className="input-group-prepend">
						<img
							src="https://designs.dmb2024.com/assets/images/icons/user.svg"
							width="40"
							className="input-group-text bg-white img-fluid"
							alt=""
						/>
					</div>
					{pollingStations.length < 1 ? (
						<select className="custom-select" disabled>
							<option value="">{loadingPolling}</option>
						</select>
					) : (
						<select
							className="custom-select"
							name="pollingStation"
							id="pollingStation"
							value={pollingStation}
							onChange={handleChange}
						>
							<option value="">Select a polling station</option>
							{pollingStations
								.sort((a, b) => a.localeCompare(b))
								.map((item, index) => (
									<option value={item} key={index}>
										{item}
									</option>
								))}
						</select>
					)}
				</div>
				<div>
					<label htmlFor="group">Group</label>
					<div className="input-group mb-3">
						<div className="input-group-prepend">
							<img
								src="https://designs.dmb2024.com/assets/images/icons/users.svg"
								width="45"
								className="input-group-text bg-white img-fluid"
								alt=""
							/>
						</div>
						<Autocomplete
							searchFor="tags"
							name="group"
							placeholder="Search for a group"
							setFieldValue={setFieldValue}
							value={group}
						/>
					</div>
					<div className="mt-4 text-center">
						<a
							href="#"
							onClick={handleSubmit}
							className="btn btn-primary btn-block"
						>
							Apply filters
						</a>
					</div>
					<p
						className="text-muted mt-1"
						style={{ cursor: 'pointer' }}
						onClick={handleReset}
					>
						Clear filters
					</p>
				</div>
			</div>
		</form>
	);
};

const ContactSearchFilterModal = ({
	show,
	onClose,
	onSubmit,
	initialFormState,
	onReset,
}) => {
	return (
		<Modal show={show} onHide={onClose} centered dialogClassName="modal-money">
			<div className="modal-content">
				<div className="modal-header border-0">
					<button
						type="button"
						className="close font-weight-light"
						onClick={onClose}
						aria-label="Close"
					>
						<img
							src="https://designs.dmb2024.com/assets/images/icons/x-square.svg"
							alt="close"
						/>
					</button>
				</div>
				<div className="modal-body mx-3">
					<Formik
						initialValues={initialFormState}
						onSubmit={onSubmit}
						onReset={onReset}
					>
						{(props) => <ContactSearchFilterForm {...props} />}
					</Formik>
				</div>
			</div>
		</Modal>
	);
};

export default ContactSearchFilterModal;
